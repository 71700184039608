:root {
  --pj-blue: #2d7bc8;
  --pj-green: #59c134;
  --pj-yellow: #fab00b;
  --pj-purple: #60238d;
  --pj-teal: #57c6aa;
  --pj-red: #f03432;
  --sticker-width: 250px;
  --sticker-height: 170px;
  --sticker-x-offset: 90px;
}

.Cal__Header__root {
  background-color: #19336e !important;
  background-image: url('img/PJ Library Logo_knockoutwhite_web.png');
  background-repeat:no-repeat;
  background-size: auto 100%;
  background-position-x: right;
  background-origin: content-box;
}
.Cal__Weekdays__root {
  background-color: var(--pj-blue) !important;
}

.holidayText {
font-size: 12px;
line-height: 12px;
text-transform: capitalize;
position: relative;
}

.overlay{
   position: fixed; /* Sit on top of the page content */
   width: 100%; /* Full width (cover the whole page) */
   height: 100%; /* Full height (cover the whole page) */
   top: 0;
   left: 0;
   right: 0;
   bottom: 0;
   background-color: rgba(0,0,0,0.5); /* Black background with opacity */
   z-index: 2; /* Specify a stack order in case you're using a different order for other elements */
   cursor: pointer; /* Add a pointer on hover */
}



.mobileHeader {display: none;}


ul.Cal__Month__row li.Cal__Day__root:nth-of-type(1) {
  border-right: 2px solid var(--pj-blue);
}
ul.Cal__Month__row li.Cal__Day__root:nth-of-type(2) {
  border-right: 2px solid var(--pj-green);
}
ul.Cal__Month__row li.Cal__Day__root:nth-of-type(3) {
  border-right: 2px solid var(--pj-yellow);
}
ul.Cal__Month__row li.Cal__Day__root:nth-of-type(4) {
  border-right: 2px solid var(--pj-purple);
}
ul.Cal__Month__row li.Cal__Day__root:nth-of-type(5) {
  border-right: 2px solid var(--pj-teal);
}
ul.Cal__Month__row li.Cal__Day__root:nth-of-type(6) {
  border-right: 2px solid var(--pj-red);
}
ul.Cal__Month__row li.Cal__Day__root:nth-of-type(7) {
  border-right: 2px solid var(--pj-blue);
}

ul.Cal__Month__row:nth-child(1) li.Cal__Day__root {
  border-top: 2px solid var(--pj-purple);
}

ul.Cal__Month__row:nth-child(2) li.Cal__Day__root {
  border-top: 2px solid var(--pj-yellow);
}

ul.Cal__Month__row:nth-child(3) li.Cal__Day__root {
  border-top: 2px solid var(--pj-green);
}

ul.Cal__Month__row:nth-child(4) li.Cal__Day__root {
  border-top: 2px solid var(--pj-blue);
}

ul.Cal__Month__row:nth-child(5) li.Cal__Day__root {
  border-top: 2px solid var(--pj-teal);
}

ul.Cal__Month__row:nth-child(6) li.Cal__Day__root {
  border-top: 2px solid var(--pj-red);
}


.Cal__Month__rows ul.Cal__Month__row:first-of-type li.Cal__Day__root:first-of-type {
  border-top-width: 6px;
  margin-top: -5px;
}



.Cal__Day__root,
.Cal__Day__root span.Cal__Day__month {
  font-size: 1vw;
}

.Cal__Day__root span.Cal__Day__month {
display: none;
}

.Cal__Day__root.Cal__Day__selected .Cal__Day__selection .Cal__Day__day {
  color: white;
  float:left;
  margin-left: 25%;
  margin-top: 20%;
  line-height: initial;
  font-size: 1vw;
  font-weight: normal;
}
/* .Cal__Day__selection span.Cal__Day__month {
  padding-top: 0;
  font-weight: normal;
} */

.Cal__Header__dateWrapper.Cal__Header__year .Cal__Header__date {
  font-size: 1.25vw;
}

.Cal__Header__dateWrapper.Cal__Header__day .Cal__Header__date {
  font-size: 2vw;
}


body {
  background-image:url('img/background.png');
  background-repeat:no-repeat;
  background-size:cover;
}

.Cal__Month__row.Cal__Month__partial:first-child li:first-child,
.Cal__Month__row:first-child li,
.Cal__Month__row:nth-child(1),
.Cal__Month__row:nth-child(2),
.Cal__Month__row:nth-child(3),
.Cal__Month__row:nth-child(4),
.Cal__Month__row:nth-child(5),
.Cal__Month__row:nth-child(6) {
  box-shadow: none;
  background-color: transparent;
}

.Cal__Month__rows {
  background: transparent;
}

.row {
  display: flex;
  margin: 40px;
}

.blur.row {
  filter: blur(5px);
}

.calendarColumn {
  box-sizing: border-box;
  flex: 80%;
}
.details {
  box-sizing: border-box;
  flex: 20%;
  padding: 20px;
  font-size: 18px;
  text-align: center;
}




.dayDetails,
.convertOverlay,
.holidayOverlay,
.aboutOverlay {
  background-color: rgba(255, 255, 255, .9);
  padding: 12px;
  border-radius: 16px;
  font-size: 1.25vw;
}

.convertOverlay,
.holidayOverlay,
.aboutOverlay {
  width: 100%;
  max-width: 600px;
  margin: 20px auto;
  text-align: center;
  cursor: auto;
  background-color: rgba(255, 255, 255, 1);
}

.aboutOverlay {
  text-align: left;
}

.holidayOverlay table {
  width: 100%;
  box-sizing: border-box;
  margin: 0 auto;

  text-align: left;
}

.holidayOverlay table td:nth-child(1) {
  font-weight: bold;
  color: var(--pj-teal);
}



#hebrewDate {
  margin: 10px;
}

.convertOverlay button {
  margin: 10px;
}


.links {
  font-size: 14px;
}
.links span {
  cursor: pointer; /* Add a pointer on hover */
}

.moonPhase {
  font-size: 250px;
}


ul.Cal__Month__row li.Cal__Day__root {
  background-repeat:no-repeat;
  background-size:cover;
}

ul.Cal__Month__row:nth-child(1) li.Cal__Day__root:nth-child(1n) {background-image: url('img/day bg 6.svg');}
ul.Cal__Month__row:nth-child(1) li.Cal__Day__root:nth-child(2n) {background-image: url('img/day bg 5.svg');}
ul.Cal__Month__row:nth-child(1) li.Cal__Day__root:nth-child(3n) {background-image: url('img/day bg 4.svg');}
ul.Cal__Month__row:nth-child(1) li.Cal__Day__root:nth-child(4n) {background-image: url('img/day bg 3.svg');}
ul.Cal__Month__row:nth-child(1) li.Cal__Day__root:nth-child(5n) {background-image: url('img/day bg 2.svg');}
ul.Cal__Month__row:nth-child(1) li.Cal__Day__root:nth-child(6n) {background-image: url('img/day bg 1.svg');}

ul.Cal__Month__row:nth-child(2) li.Cal__Day__root:nth-child(1n) {background-image: url('img/day bg 2.svg');}
ul.Cal__Month__row:nth-child(2) li.Cal__Day__root:nth-child(2n) {background-image: url('img/day bg 4.svg');}
ul.Cal__Month__row:nth-child(2) li.Cal__Day__root:nth-child(3n) {background-image: url('img/day bg 6.svg');}
ul.Cal__Month__row:nth-child(2) li.Cal__Day__root:nth-child(4n) {background-image: url('img/day bg 5.svg');}
ul.Cal__Month__row:nth-child(2) li.Cal__Day__root:nth-child(5n) {background-image: url('img/day bg 3.svg');}
ul.Cal__Month__row:nth-child(2) li.Cal__Day__root:nth-child(6n) {background-image: url('img/day bg 1.svg');}

ul.Cal__Month__row:nth-child(3) li.Cal__Day__root:nth-child(1n) {background-image: url('img/day bg 1.svg');}
ul.Cal__Month__row:nth-child(3) li.Cal__Day__root:nth-child(2n) {background-image: url('img/day bg 4.svg');}
ul.Cal__Month__row:nth-child(3) li.Cal__Day__root:nth-child(3n) {background-image: url('img/day bg 3.svg');}
ul.Cal__Month__row:nth-child(3) li.Cal__Day__root:nth-child(4n) {background-image: url('img/day bg 6.svg');}
ul.Cal__Month__row:nth-child(3) li.Cal__Day__root:nth-child(5n) {background-image: url('img/day bg 5.svg');}
ul.Cal__Month__row:nth-child(3) li.Cal__Day__root:nth-child(6n) {background-image: url('img/day bg 2.svg');}

ul.Cal__Month__row:nth-child(4) li.Cal__Day__root:nth-child(1n) {background-image: url('img/day bg 6.svg');}
ul.Cal__Month__row:nth-child(4) li.Cal__Day__root:nth-child(2n) {background-image: url('img/day bg 3.svg');}
ul.Cal__Month__row:nth-child(4) li.Cal__Day__root:nth-child(3n) {background-image: url('img/day bg 1.svg');}
ul.Cal__Month__row:nth-child(4) li.Cal__Day__root:nth-child(4n) {background-image: url('img/day bg 4.svg');}
ul.Cal__Month__row:nth-child(4) li.Cal__Day__root:nth-child(5n) {background-image: url('img/day bg 2.svg');}
ul.Cal__Month__row:nth-child(4) li.Cal__Day__root:nth-child(6n) {background-image: url('img/day bg 5.svg');}

ul.Cal__Month__row:nth-child(5) li.Cal__Day__root:nth-child(1n) {background-image: url('img/day bg 5.svg');}
ul.Cal__Month__row:nth-child(5) li.Cal__Day__root:nth-child(2n) {background-image: url('img/day bg 6.svg');}
ul.Cal__Month__row:nth-child(5) li.Cal__Day__root:nth-child(3n) {background-image: url('img/day bg 1.svg');}
ul.Cal__Month__row:nth-child(5) li.Cal__Day__root:nth-child(4n) {background-image: url('img/day bg 3.svg');}
ul.Cal__Month__row:nth-child(5) li.Cal__Day__root:nth-child(5n) {background-image: url('img/day bg 2.svg');}
ul.Cal__Month__row:nth-child(5) li.Cal__Day__root:nth-child(6n) {background-image: url('img/day bg 4.svg');}

ul.Cal__Month__row:nth-child(6) li.Cal__Day__root:nth-child(1n) {background-image: url('img/day bg 4.svg');}
ul.Cal__Month__row:nth-child(6) li.Cal__Day__root:nth-child(2n) {background-image: url('img/day bg 2.svg');}
ul.Cal__Month__row:nth-child(6) li.Cal__Day__root:nth-child(3n) {background-image: url('img/day bg 3.svg');}
ul.Cal__Month__row:nth-child(6) li.Cal__Day__root:nth-child(4n) {background-image: url('img/day bg 5.svg');}
ul.Cal__Month__row:nth-child(6) li.Cal__Day__root:nth-child(5n) {background-image: url('img/day bg 1.svg');}
ul.Cal__Month__row:nth-child(6) li.Cal__Day__root:nth-child(6n) {background-image: url('img/day bg 6.svg');}


/* Stickers */

.sticker {
  pointer-events: none;
}

/* Sticker positioning is difficult to do given that they're nested in a div
but are required to extend past it. This CSS hack solves that issue. */
.sticker::before {
background-repeat: no-repeat;
background-size: cover;
content: "";
display: block;
width: var(--sticker-width); /* Dynamically regenerated by js */
height: var(--sticker-height); /* Dynamically regenerated by js */
margin-top: calc(var(--sticker-height) * -1); /* inverse of height to offset size */
margin-left: calc((var(--sticker-width) - var(--sticker-height)) * -1);
position: relative;
z-index: 2;
}

.Cal__Day__root.Cal__Day__enabled:hover {
  z-index: auto;
}
/* Sticker image classes are dynamically added based on HebCal Holiday Desc Data */

.sticker[class*="Shabbat_1"]::before {background-image: url("./img/shabbat 1.svg");}
.sticker[class*="Shabbat_2"]::before {background-image: url("./img/shabbat 2.svg");}
.sticker[class*="Shabbat_3"]::before {background-image: url("./img/shabbat 3.svg");}
.sticker[class*="Shabbat_4"]::before {background-image: url("./img/shabbat 4.svg");}


/* Rosh Chodesh comes first b/c it may get overwritten (e.g. Rosh Hashanah, Chanukah, etc) */
.sticker[class*="Rosh_Chodesh"]::before {background-image: url("./img/rosh chodesh 1.svg");}


.sticker.Purim::before {background-image: url("./img/purim.svg");}


.sticker.Shavuot_I::before {background-image: url("./img/shavuot 1.svg");}
.sticker.Shavuot_II::before {background-image: url("./img/shavuot 2.svg");}

/* HebCal "Pesach" & "Sukkot" descriptions includes (CH"M) tags -- we ignore them */
.sticker[class*="Pesach_I"]::before {background-image: url("./img/passover 1.svg");}
.sticker[class*="Pesach_II"]::before {background-image: url("./img/passover 2.svg");}
.sticker[class*="Pesach_III"]::before {background-image: url("./img/passover 3.svg");}
.sticker[class*="Pesach_IV"]::before {background-image: url("./img/passover 4.svg");}
.sticker[class*="Pesach_V"]::before {background-image: url("./img/passover 5.svg");}
.sticker[class*="Pesach_VI"]::before {background-image: url("./img/passover 6.svg");}
.sticker[class*="Pesach_VII"]::before {background-image: url("./img/passover 7.svg");}
.sticker[class*="Pesach_VIII"]::before {background-image: url("./img/passover 8.svg");}

.sticker[class*="Sukkot_I"]::before {background-image: url("./img/sukkot 1.svg");}
.sticker[class*="Sukkot_II"]::before {background-image: url("./img/sukkot 2.svg");}
.sticker[class*="Sukkot_III"]::before {background-image: url("./img/sukkot 3.svg");}
.sticker[class*="Sukkot_IV"]::before {background-image: url("./img/sukkot 4.svg");}
.sticker[class*="Sukkot_V"]::before {background-image: url("./img/sukkot 5.svg");}
.sticker[class*="Sukkot_VI"]::before {background-image: url("./img/sukkot 6.svg");}
.sticker[class*="Sukkot_VII"]::before {background-image: url("./img/sukkot 7.svg");}


/* HebCal desc for "Rosh Hashanah" first day always includes the Hebrew year.
This selector should be for another 220 years */
.sticker[class*="Rosh_Hashana_5"]::before {background-image: url("./img/rosh hashanah 1.svg");}
.sticker.Rosh_Hashana_II::before {background-image: url("./img/rosh hashanah 2.svg");}

.sticker.Yom_Kippur::before {background-image: url("./img/yom kippur.svg");}

.sticker.Shmini_Atzeret::before {background-image: url("./img/shemini atzeret.svg");}
.sticker.Simchat_Torah::before {background-image: url("./img/simchat torah.svg");}

.sticker.Yom_HaAtzma_ut::before {background-image: url("./img/yom ha\'atzmaut.svg");}

.sticker.Tu_BiShvat::before {background-image: url("./img/tu bishvat.svg");}



/* We start with "2" Because HebCal Counts hanukkah by nights
e.g. "Chanuakah: 1 Candle," "Channukah: 2 Candle,"... "Chanukah 8th Day" */
.sticker[class*="Chanukah__2"]::before {background-image: url("./img/hanukkah 1.svg");}
.sticker[class*="Chanukah__3"]::before {background-image: url("./img/hanukkah 2.svg");}
.sticker[class*="Chanukah__4"]::before {background-image: url("./img/hanukkah 3.svg");}
.sticker[class*="Chanukah__5"]::before {background-image: url("./img/hanukkah 4.svg");}
.sticker[class*="Chanukah__6"]::before {background-image: url("./img/hanukkah 5.svg");}
.sticker[class*="Chanukah__7"]::before {background-image: url("./img/hanukkah 1.svg");}
.sticker[class*="Chanukah__8"]::before {background-image: url("./img/hanukkah 7.svg");}
.sticker[class*="Chanukah__8th"]::before {background-image: url("./img/hanukkah 8.svg");}

.sticker[class*="Rosh_Chodesh_Tevet"]::before {background-image: url("./img/hanukkah 6.svg");} /* Chanukah sticker w/ moon */


.Cal__Today__root .Cal__Today__chevron {
  margin-left: -65px;
}

.Cal__Header__wrapper {
  pointer-events: none;
}

.Cal__Day__root.Cal__Day__selected,
.Cal__Day__root.Cal__Day__selected .Cal__Day__selection,
.Cal__Day__root.Cal__Day__today .Cal__Day__selection,
.Cal__Day__root.Cal__Day__today > span {
  background-color: transparent !important;
  color: rgba(0,0,0,0) !important;
}

.Cal__Day__root.Cal__Day__selected {background-image: url('img/day bg active.svg') !important;}

.Cal__Month__rows {
  counter-reset: dateCounter;
}

.Cal__Day__root {
  counter-increment: dateCounter;
}


.Cal__Day__root::before,
.Cal__Day__root.Cal__Day__enabled:hover::before,
.Cal__Day__root.Cal__Day__today::before,
.Cal__Day__root.Cal__Day__selected.Cal__Day__enabled::before {
  content: counter(dateCounter);
  top: calc(var(--sticker-height) * -.35);
  left: calc(var(--sticker-height) * -.35);
  position: relative;
  z-index: 3;
  color: black;
  background-color: inherit;
  border-bottom-left-radius: inherit;
  border-bottom-right-radius: inherit;
  border-top-left-radius: inherit;
  border-top-right-radius: inherit;
  height: auto;
  margin-left: 0;
	margin-top: 0;
	opacity: 1;
  width: auto;
  border: none;
  box-shadow: none;
}



.Cal__Day__root.Cal__Day__selected .Cal__Day__selection {
  height: calc(var(--sticker-height) * .25);
  width: calc(var(--sticker-height) * .25);
  top: 0;
  left: 0;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  border-bottom-left-radius: 0;
  background-color: rgb(25, 51, 110) !important;
  color: white !important;
  margin: 0;

}

.Cal__Day__root {
  color: rgba(0,0,0,0)
}



@media only screen and (max-width: 768px) {
  /* For mobile phones: */
  .row {
    margin: 0;
    display: block;
  }

  .calendarColumn {
    width: 100%;
  }

  .moonPhase {
    font-size: 36px;
    float: left;
    margin-right: 10px;
  }

  .details {
    width: 100%;
    position: fixed;
    bottom: 0;
    z-index: 5;
    margin: 0;
    padding: 10px;
    background-color: rgba(255, 255, 255, 1);
    height: 200px;
    overflow-y: scroll;
    text-align: left;
    border-top: 2px solid black;

  }

  .dayDetails {
    font-size: 16px;
    margin-top: -20px;
  }

  .convertOverlay,
  .holidayOverlay,
  .aboutOverlay {
    width: 100%;
    max-width: 400px;
    margin: 20px auto;
    text-align: center;
    cursor: auto;
  }

  .aboutOverlay {
    text-align: left;
  }

.dayDetails, .convertOverlay, .holidayOverlay, .aboutOverlay {
  font-size: 16px
}


.Cal__Header__dateWrapper.Cal__Header__day .Cal__Header__date {
  display: none;
}

.Cal__Header__dateWrapper.Cal__Header__year .Cal__Header__date {
  display: none;
}

.Cal__Header__wrapper {
  display: none;
}

.Cal__Header__root {
  display: none;
}


.mobileHeader {
  display: block;
  background-color: #19336e !important;
  background-image: url('img/PJ Library Logo_knockoutwhite_web.png');
  background-repeat:no-repeat;
  background-size: auto 100%;
  box-sizing: border-box;
  padding: 10px;
  background-position-x: center;
  background-origin: content-box;
  width:100%;
  height: 50px;

}

}
